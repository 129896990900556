import "../App.css"
import fotoA from "../Img/retrato_alfonso.jpg"

import { Helmet } from "react-helmet"

function GraciasBlog() {
    return (
        <>

            <Helmet>
                <title>El Blog de Alfonso - Alfonso Nuñez Arquitectos</title>
            </Helmet>

            <section className="py-3">
                <div className="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto text-center">
                    <div class="row row-cols-1 row-cols-md-1 align-items-center">



                        <div class="col">
                            <div class="card h-100 ">
                                <div class="card-body">
                                    
                                    <h1 className="fw-medium lh-sm text-center">¡Gracias por tu registro!</h1>

                                   
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>            


            <section className="py-3" style={{marginBottom:"150px"}}>
                <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 mx-auto text-center">
                    <div class="row row-cols-1 row-cols-md-1 ">



                        <div class="col">
                            <div class="card h-100 ">
                                <div id="content_body" class="card-body">
                                    
                                    
                                    <h6 className="lh-base fw-light text-center">Juntos, reflexionemos sobre lo que significa habitar y crear espacios con propósito.</h6>
                                    <a href="/" type="button" class="btn btn-outline-dark rounded-0 col-12 col-md-3 mt-3 btn-lg mt-5">Ir a Inicio</a>

                                   
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>




        </>
    );
}

export default GraciasBlog;