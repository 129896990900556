import "../App.css"
import fotoA from "../Img/retrato_alfonso.jpg"

import { Helmet } from "react-helmet"

function BlogAlfonso() {
    return (
        <>

            <Helmet>
                <title>El Blog de Alfonso - Alfonso Nuñez Arquitectos</title>
            </Helmet>

            <section className="py-3">
                <div className="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto text-center">
                    <div class="row row-cols-1 row-cols-md-1 align-items-center">



                        <div class="col">
                            <div class="card h-100 ">
                                <div class="card-body">
                                    
                                    <h1 className="fw-medium lh-sm text-center">El Blog de Alfonso</h1>

                                   
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>            


            <section className="py-3" style={{marginBottom:"200px"}}>
                <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 mx-auto text-center">
                    <div class="row row-cols-1 row-cols-md-2 ">

                    <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                    <img src={fotoA} className="img-fluid" alt="Foto de Alfonso" />
                                </div>
                            </div>
                        </div>


                        <div class="col">
                            <div class="card h-100 ">
                                <div id="content_body" class="card-body">
                                    
                                    
                                    <h6 className="lh-base fw-light text-justify">Hola, soy Alfonso Núñez, arquitecto apasionado por diseñar espacios que trascienden lo estético para convertirse en algo profundamente humano. Para mí, la arquitectura es un diálogo constante entre las personas y los espacios, una búsqueda de soluciones que no solo cumplan con una función, sino que también enriquezcan nuestra forma de vivir y sentir.</h6>
                                    <h6 className="lh-base fw-light mt-3 text-justify">Al unirte a mi comunidad, entrarás a un espacio exclusivo donde comparto:</h6>

                                    <ul style={{textAlign: "left"}}>
                                        <li>Reflexiones sobre arquitectura y vida.</li>
                                        <li>Análisis de temas como funcionalidad, materiales adecuados y sostenibles.</li>
                                        <li>Lecciones aprendidas trabajando en proyectos residenciales y comerciales.</li>
                                        <li>Inspiración para transformar cualquier espacio en algo único y significativo.</li>
                                    </ul>

                                        <div className="mt-4" style={{height:"500px"}}>

                                        <iframe
                                           src="https://api.circulocuadro.com/widget/form/LEFFxr2qDQpqwAFrUf7I"
                                           style={{width:"100%",height:"100%",border:"none"}}
                                           id="inline-LEFFxr2qDQpqwAFrUf7I" 
                                           data-layout="{'id':'INLINE'}"
                                           data-trigger-type="alwaysShow"
                                           data-trigger-value=""
                                           data-activation-type="alwaysActivated"
                                           data-activation-value=""
                                           data-deactivation-type="neverDeactivate"
                                           data-deactivation-value=""
                                           data-form-name="Form 0"
                                           data-height="468"
                                           data-layout-iframe-id="inline-LEFFxr2qDQpqwAFrUf7I"
                                           data-form-id="LEFFxr2qDQpqwAFrUf7I"
                                           title="Form 0">
                                        </iframe>
                                        <script src="https://api.circulocuadro.com/js/form_embed.js"></script>

                                        </div>
                                   
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>




        </>
    );
}

export default BlogAlfonso;